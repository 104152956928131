import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ResetPassword.css'; // Ensure you create a corresponding CSS file
import usePageTitle from '../../../components/usePageTitle';

const ResetPassword = () => {
  usePageTitle("Reset Password - RixStonGamer");

  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { newPassword, confirmPassword } = formData;

    // Simple validation: check if passwords match
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      setSuccess('');
      return;
    }

    // Simulate successful password reset
    setSuccess("Your password has been reset successfully.");
    setError('');
    setFormData({ newPassword: '', confirmPassword: '' }); // Clear form
  };

  return (
    <div className="reset-password">
      <h1>Reset Password</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="newPassword">New Password:</label>
        <input
          type="password"
          id="newPassword"
          name="newPassword"
          value={formData.newPassword}
          onChange={handleChange}
          required
        />

        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />

        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}

        <button type="submit">Reset Password</button>
        <p>
          Remembered your password? <Link to="/signin">Sign In</Link>
        </p>
      </form>
    </div>
  );
};

export default ResetPassword;
