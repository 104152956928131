import React, { useState, useEffect } from 'react';
import './Todo.css';
import usePageTitle from '../../components/usePageTitle';

const Todo = () => {
    usePageTitle("Todo - RixStonGamer");
    const [inputText, setInputText] = useState('');
    const [todos, setTodos] = useState([]);
    const [editId, setEditId] = useState(null);

    useEffect(() => {
        const storedTodos = JSON.parse(localStorage.getItem('todos')) || [];
        setTodos(storedTodos);
    }, []);

    useEffect(() => {
        localStorage.setItem('todos', JSON.stringify(todos));
    }, [todos]);

    const handleAddTodo = () => {
        if (inputText.trim().length === 0) {
            alert("You must write something in your Todo");
            return;
        }

        if (editId !== null) {
            setTodos(todos.map((todo, index) => (index === editId ? inputText : todo)));
            setEditId(null);
        } else {
            setTodos([...todos, inputText]);
        }
        setInputText('');
    };

    const handleDeleteTodo = (index) => {
        setTodos(todos.filter((_, i) => i !== index));
    };

    const handleEditTodo = (index) => {
        setInputText(todos[index]);
        setEditId(index);
    };

    return (
        <div className="container">
                <h1 className='todo-title'>To Do List</h1>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Add a new Todo..."
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                />
                <input
                    type="button"
                    value={editId !== null ? "Edit" : "Add"}
                    onClick={handleAddTodo}
                />
            </div>
            <ul className="todoList">
                {todos.map((todo, index) => (
                    <li key={index}>
                        <p>{todo}</p>
                        <button className="btn editBtn" onClick={() => handleEditTodo(index)}>Edit</button>
                        <button className="btn deleteBtn" onClick={() => handleDeleteTodo(index)}>Remove</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Todo;
