import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePageTitle from '../../../components/usePageTitle';
import './Login.css';

const Login = () => {
  usePageTitle('Sign In - RixStonGamer');

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = formData;

    // Simulate login with dummy credentials
    if (email === 'shihpharujjaman@gmail.com' && password === 'Shipharuj#69') {
      setError(''); // Clear any previous errors
      navigate('/'); // Redirect to the homepage after successful login
    } else {
      setError('Invalid email or password. Please try again.');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-card">
        <h2>Sign In to Your Account</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          {error && <p className="error">{error}</p>}
          <button type="submit" className="btn">Sign In</button>
        </form>
        <div className="or-container">
          <hr className="line" />
          <p className="or-text">Or</p>
          <hr className="line" />
        </div>
        <div className="btn-group">
          <a className='btn-register' href="/signup">Register</a>
          <a className='btn-forgot' href="/forgot-password">Forgot Password</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
