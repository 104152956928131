import React from 'react';
import './NotFound.css';
import usePageTitle from '../../components/usePageTitle';

const NotFound = () => {
  usePageTitle("Page Not Found - RixStonGamer");
  return (
    <div className="not-found">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
