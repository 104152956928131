import React from 'react';
import usePageTitle from "../../components/usePageTitle";
import './About.css';


const About = () => {
  usePageTitle("About - RixStonGamer");
  return (
    <div className="about">
      <h1>About Me</h1>
      <section id="experience" className="experience-section">
        <div className="container">
          <p>Hello I'm SHIPHARUJ, better known as RixStonGamer, im an Indian YouTuber and live-streamer, I make gaming videos on YouTube.</p>
          <p>RixStonGamer, a passionate gamer, gaming video creator, student, and streamer.</p>

          <h2>Why I play Games ?</h2>
          <p>Gaming isn't just a hobby for me! it's a way of life. I dive into virtual worlds, conquer challenges, and embark on epic adventures across various gaming platforms.</p>

          <h2>Why I Make Gaming Videos ?</h2>
          <p>I love sharing my gaming experiences with others. Through my YouTube channel or YouTube streams, I bring exciting gameplay, entertaining commentary, and helpful tips to fellow gamers worldwide.</p>

          <h2>Im Student</h2>
          <p>Beyond gaming, I'm committed to my education. As a student, I'm constantly learning and growing, balancing my academic pursuits with my passion for gaming.</p>

          <h2>Streamer</h2>
          <p>Join me live as I stream my gaming sessions, interact with viewers, and build a community around our shared love for games. Whether it's intense battles, hilarious moments, or heartfelt discussions, there's always something exciting happening on my streams.</p>
          
        </div>
          <a href="https://www.youtube.com/@rixstongamer?sub_confirmation=1" target="_blank" rel="noopener noreferrer">
          <button class="heart-btn">
    Subscribe
    <div class="heart1">

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"></path>
        </svg>
    </div>
    <div class="heart2">

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"></path>
        </svg>
    </div>
    <div class="heart3">

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"></path>
        </svg>
    </div>
</button></a>
      </section>
    </div>
    
  );
}

export default About;
