import React, { useState } from 'react';
import './PasswordGenerator.css';
import usePageTitle from '../../components/usePageTitle';

const PasswordGenerator = () => {
  usePageTitle("Password Generator - RixStonGamer");
  const [password, setPassword] = useState('');
  const [length, setLength] = useState(8);
  const [lowercase, setLowercase] = useState(true);
  const [uppercase, setUppercase] = useState(true);
  const [numbers, setNumbers] = useState(true);
  const [symbols, setSymbols] = useState(true);

  const generatePassword = () => {
    if (!lowercase && !uppercase && !numbers && !symbols) {
      alert('Please select at least one option for the password criteria.');
      return;
    }

    const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
    const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const allNumbers = '0123456789';
    const allSymbols = '~!@#$%^&*';

    let allChars = '';
    if (lowercase) allChars += lowerChars;
    if (uppercase) allChars += upperChars;
    if (numbers) allChars += allNumbers;
    if (symbols) allChars += allSymbols;

    let generatedPassword = '';
    for (let i = 0; i < length; i++) {
      generatedPassword += allChars.charAt(Math.floor(Math.random() * allChars.length));
    }
    setPassword(generatedPassword);
  };

  const copyToClipboard = () => {
    if (password) {
      navigator.clipboard.writeText(password);
      alert('Password copied to clipboard!');
    }
  };

  return (
    <div className="password-generator-container">
      <div className="password-generator">
      <h1 className='title'>Password Generator</h1>


        <div className="input-section">
          <div className="input-box">
            <input type="text" value={password} readOnly placeholder="Your password will appear here" />
            <button className="copy-btn" onClick={copyToClipboard} title="Copy to clipboard">
              Copy
            </button>
          </div>

          <div className="range-section">
            <label className="range-label">
              Password Length: <span>{length}</span>
            </label>
            <input
              type="range"
              min="8"
              max="20"
              value={length}
              onChange={(e) => setLength(e.target.value)}
              className="range-input"
            />
          </div>

          <div className="options">
            <label className="option">
              <input type="checkbox" checked={lowercase} onChange={() => setLowercase(!lowercase)} />
              Lowercase
            </label>
            <label className="option">
              <input type="checkbox" checked={uppercase} onChange={() => setUppercase(!uppercase)} />
              Uppercase
            </label>
            <label className="option">
              <input type="checkbox" checked={numbers} onChange={() => setNumbers(!numbers)} />
              Numbers
            </label>
            <label className="option">
              <input type="checkbox" checked={symbols} onChange={() => setSymbols(!symbols)} />
              Symbols
            </label>
          </div>

          <button className="generate-btn" onClick={generatePassword}>
            Generate Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordGenerator;
