import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/Logo/rsg-text-logo.png';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavClick = (path) => {
    setIsOpen(false);
    navigate(path);
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="logo-container">
        <Link to="/" onClick={() => handleNavClick('/')}>
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <nav className={isOpen ? 'open' : ''}>
        <ul>
          <li><Link to="/" onClick={() => handleNavClick('/')}>Home</Link></li>
          <li><Link to="/about" onClick={() => handleNavClick('/about')}>About</Link></li>
          <li><Link to="/projects" onClick={() => handleNavClick('/projects')}>Projects</Link></li>
          <li><Link to="/services" onClick={() => handleNavClick('/services')}>Services</Link></li>
          <li><Link to="/gallery" onClick={() => handleNavClick('/gallery')}>Gallery</Link></li>
          <li><Link to="/contact" onClick={() => handleNavClick('/contact')}>Contact</Link></li>
          <li><Link to="/login" onClick={() => handleNavClick('/login')}>Login</Link></li>
        </ul>
      </nav>
      <label className="hamburger">
        <input type="checkbox" checked={isOpen} onChange={toggleMenu} />
        <svg viewBox="0 0 32 32">
          <path className="line line-top-bottom" d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"></path>
          <path className="line" d="M7 16 27 16"></path>
        </svg>
      </label>
    </header>
  );
}

export default Header;
