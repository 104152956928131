import React from "react";
import { FaYoutube, FaInstagram, FaDiscord, FaWhatsapp, FaTwitter } from "react-icons/fa";
import styles from './LinkPage.module.css';
import RixStonGamerLogo from '../../assets/Logo/RixStonGamer.jpg';
import usePageTitle from '../../components/usePageTitle';

const LinkPage = () => {
  usePageTitle("Links - RixStonGamer");
  return (
    <div className={styles.linkpageContainer}>
      <div className={styles.profile}>
        <img
          src={RixStonGamerLogo}
          alt="Rixston Gamer"
          className={styles.profileImg}
        />
        <h1>RixStonGamer</h1>
        <p className={styles.tagline}>Gaming creator for Minecraft, BGMI, FreeFire. Subscribe for gameplay content.</p>
      </div>

      <div className={styles.links}>
        <a href="https://www.youtube.com/@rixstongamer?sub_confirmation=1" target="_blank" rel="noopener noreferrer">
          <FaYoutube className={`${styles.icon} ${styles.youtube}`} /> Subscribe on YouTube
        </a>
        <a href="https://instagram.com/rixstongamer" target="_blank" rel="noopener noreferrer">
          <FaInstagram className={`${styles.icon} ${styles.instagram}`} /> Follow on Instagram
        </a>
        <a href="https://discord.gg/uMNyVRYydZ" target="_blank" rel="noopener noreferrer">
          <FaDiscord className={`${styles.icon} ${styles.discord}`} /> Join Discord
        </a>
        <a href="https://whatsapp.com/channel/0029VaNfZUY3GJP3VMek2m3z" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp className={`${styles.icon} ${styles.whatsapp}`} /> Message on WhatsApp
        </a>
        <a href="https://x.com/iamshipharuj" target="_blank" rel="noopener noreferrer">
          <FaTwitter className={`${styles.icon} ${styles.twitter}`} /> Follow on X (Twitter)
        </a>
      </div>
    </div>
  );
};

export default LinkPage;
