import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS for styling
import './styles/App.css'; // Custom CSS

// Shared Components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import BackToTop from './components/BackToTop/BackToTop';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Loader from './components/Loader/Loader'; // Loader for page loading

// Main Pages
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Blog from './pages/Blog/Blog';
import BlogPost from './pages/BlogPost/BlogPost';
import Projects from './pages/Projects/Projects';
import Services from './pages/Services/Services';
import Gallery from './pages/Gallery/Gallery';
import Contact from './pages/Contact/Contact';
import NotFound from './pages/ErrorPages/NotFound';
import LinkPage from './pages/LinkPage/LinkPage';

// Authentication Pages
import Login from './pages/Authentication/Login/Login';
import SignUp from './pages/Authentication/SignUp/SignUp';
import ForgotPassword from './pages/Authentication/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/Authentication/ResetPassword/ResetPassword';

// Project Pages
import TicTacToe from './project/TicTacToe/TicTacToe';
import Todo from './project/Todo/Todo';
import WhackaMole from './project/WhackaMole/WhackaMole';
import ColorPicker from './project/ColorPicker/ColorPicker';
import PasswordGenerator from './project/PasswordGenerator/PasswordGenerator';
import QRCodeGenerator from './project/QRCodeGenerator/QRCodeGenerator';

const App = () => {
  // State to manage loading
  const [loading, setLoading] = useState(true);

  // Simulate a loading effect
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 900);
    return () => clearTimeout(timer); // Cleanup
  }, []);

  // Show loader if loading
  if (loading) {
    return <Loader />;
  }

  return (
    <Router>
      {/* ScrollToTop ensures the page starts at the top when navigating */}
      <ScrollToTop />
      <div className="app-container">
        {/* Back to Top Button */}
        <BackToTop />

        {/* Header */}
        <Header />

        {/* Main Content */}
        <Routes>
          {/* Core Pages */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:postId" element={<BlogPost />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/services" element={<Services />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/links" element={<LinkPage />} />

          {/* Authentication Pages */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* Project Pages */}
          <Route path="/project/tic-tac-toe" element={<TicTacToe />} />
          <Route path="/project/todo" element={<Todo />} />
          <Route path="/project/whack-a-mole" element={<WhackaMole />} />
          <Route path="/project/color-picker" element={<ColorPicker />} />
          <Route path="/project/password-generator" element={<PasswordGenerator />} />
          <Route path="/project/qr-code-generator" element={<QRCodeGenerator />} />

          {/* Fallback Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>

        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
};

export default App;
