import React, { useState, useEffect } from 'react';
import './Home.css';
import RixStonGamerLogo from '../../assets/Logo/RixStonGamer.jpg';
import BGMIxRIPPONLogo from '../../assets/Logo/BGMIxRIPPON.jpg';
import usePageTitle from '../../components/usePageTitle';

function Home() {
  usePageTitle("RixStonGamer");

  const [subscribers, setSubscribers] = useState(null);

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=UCfPa8P0QGTnd2UM30ttngGQ&key=AIzaSyBjtZsrxskg69QLNwkpQU1v3g1qOTtlnDY`
        );
        const data = await response.json();
        setSubscribers(data.items[0].statistics.subscriberCount);
      } catch (error) {
        console.error('Error fetching subscriber count:', error);
      }
    };

    fetchSubscribers();
  }, []);

  return (
    <div className="home">
      <h1 className="home-text">Home</h1>

      {/* Games Section */}
      <section className="games-section">
        <h2 className="section-title">Games I Play</h2>
        <p className="intro-text">
          Also, you can play with me. Join our <a href="https://discord.gg/uMNyVRYydZ" target='-'> Discord </a> Server for Play Games!
        </p>
        <div className="games-list-container">
          <ul className="games-list">
            <li>Minecraft</li>
            <li>BGMI</li>
            <li>GTA V</li>
            <li>Valorant</li>
            <li>Warframe</li>
            <li>Fortnite</li>
            <li>Roblox</li>
            <li>PUBG PC</li>
            <li>Fall Guys</li>
            <li>Genshin Impact</li>
          </ul>
        </div>
      </section>

      {/* Channel Section */}
      <p className="description-p">
        We are here to provide you the greatest user experience in the gaming world.
      </p>
      <section className="channel-section">
        <div className="channel-card">
          <img src={RixStonGamerLogo} alt="RixStonGamer" className="channel-logo" />
          <p className="channel-description">RixStonGamer<br />Main Channel<br />for PC Games</p>
          <a href="https://www.youtube.com/@RixStonGamer?sub_confirmation=1" target="_blank" rel="noopener noreferrer" className="explore-button">Explore</a>
        </div>
        <div className="channel-card">
          <img src={BGMIxRIPPONLogo} alt="BGMIxRIPPON" className="channel-logo" />
          <p className="channel-description">BGMIxRIPPON<br />Second Channel<br />for Mobile Games</p>
          <a href="https://www.youtube.com/@BGMIxRIPPON?sub_confirmation=1" target="_blank" rel="noopener noreferrer" className="explore-button">Explore</a>
        </div>
      </section>

            {/* New Card Linking to LinkPage.js */}
            <section className="linkpage-section">
        <div className="linkpage-card">
          <h3 className="linkpage-title">Visit My Links</h3>
          <p className="linkpage-description">Check out all my social media links and more!</p>
          <a href="/links" className="linkpage-button">Visit LinkPage</a>
        </div>
      </section>

      {/* ID Section */}
      <section className="id-section">
        <h2 className="id-title">Game UID and Details</h2>
        <ul className="id-list">
          <li>Minecraft User Name - RixStonGamer</li>
          <li>RockStar Games User Name - iAmRippon</li>
          <li>BGMI ID - 5393e67581</li>
          <li>FreeFire ID - 1955e31454</li>
          <li>Valorant User ID - RixStonGamer#8282</li>
          <li>Steam User Name - @iamrippon</li>
          <li>Discord Tag - RixStonGamer#8282</li>
        </ul>
      </section>

      {/* Live Subscribers Count Section */}
      <section className="subscribers-section">
        <hr className='hr-top'></hr>
        <h1 className="channel-name">RixStonGamer</h1>
        <h1 className="subscribers-title">Subscribers</h1>
        {subscribers !== null ? (
          <h2 className="subscribers-count">{subscribers}</h2>
        ) : (
          <h2 className='subscribers-count'>Counting...</h2>
        )}
        <hr className='hr-bottom'></hr>
      </section>
    </div>
  );
}

export default Home;
