import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import {
    Button,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    Box,
    Container,
    CircularProgress,
    Snackbar,
    Alert,
} from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import usePageTitle from '../../components/usePageTitle';
import './QRCodeGenerator.css';
import html2canvas from 'html2canvas';

const Input = styled('input')({
    display: 'none',
});

const QRCodeGenerator = () => {
    usePageTitle("QR Code Generator - RixStonGamer");

    const [inputValue, setInputValue] = useState('');
    const [dataType, setDataType] = useState('url');
    const [qrColor, setQrColor] = useState('#000000');
    const [qrBgColor, setQrBgColor] = useState('#ffffff');
    const [imageFormat, setImageFormat] = useState('png');
    const [qrBorder, setQrBorder] = useState('none');
    const [qrBorderRadius, setQrBorderRadius] = useState(0);
    const [qrShadow, setQrShadow] = useState('none');
    const [preset, setPreset] = useState('');
    const [customLogo, setCustomLogo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const colorOptions = [
        '#000000', '#FF5733', '#33FF57', '#3357FF',
        '#FFD733', '#33FFF9', '#FF33A6',
        '#8A2BE2', '#5F9EA0', '#D2691E',
    ];

    const bgColorOptions = [
        '#ffffff', '#e6e6e6', '#f0f0f0', '#f5f5f5', '#cccccc', '#dcdcdc',
        '#b3b3b3', '#999999', '#808080', '#666666', '#000000',
    ];

    const presets = {

                default: { qrColor: '#000000', qrBgColor: '#ffffff', qrBorder: 'none', qrBorderRadius: 0, qrShadow: 'none' },
        red: { qrColor: '#FF5733', qrBgColor: '#ffffff', qrBorder: 'none', qrBorderRadius: 0, qrShadow: 'none' },
        blue: { qrColor: '#3357FF', qrBgColor: '#ffffff', qrBorder: 'none', qrBorderRadius: 0, qrShadow: 'none' },
        green: { qrColor: '#33FF57', qrBgColor: '#ffffff', qrBorder: 'none', qrBorderRadius: 0, qrShadow: 'none' },

        'Classic': {
            qrColor: '#ffffff',
            qrBgColor: '#000000',
            qrBorder: '2px solid #000000',
            qrBorderRadius: 0,
            qrShadow: 'none',
        },
        'Bold': {
            qrColor: '#FF5733',
            qrBgColor: '#333333',
            qrBorder: '2px solid #FF5733',
            qrBorderRadius: 10,
            qrShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)',
        },
        'Soft': {
            qrColor: '#3357FF',
            qrBgColor: '#e6e6e6',
            qrBorder: '1px solid #3357FF',
            qrBorderRadius: 15,
            qrShadow: 'none',
        },
        'Vibrant': {
            qrColor: '#FFD733',
            qrBgColor: '#FF5733',
            qrBorder: '3px solid #FFD733',
            qrBorderRadius: 20,
            qrShadow: '5px 5px 15px rgba(255, 87, 51, 0.5)',
        },
        'Modern': {
            qrColor: '#8A2BE2',
            qrBgColor: '#f0f0f0',
            qrBorder: '2px solid #8A2BE2',
            qrBorderRadius: 25,
            qrShadow: '3px 3px 12px rgba(138, 43, 226, 0.3)',
        },
        'Elegant': {
            qrColor: '#5F9EA0',
            qrBgColor: '#f5f5f5',
            qrBorder: '1px solid #5F9EA0',
            qrBorderRadius: 20,
            qrShadow: '1px 1px 8px rgba(95, 158, 160, 0.4)',
        },
        'Dynamic': {
            qrColor: '#D2691E',
            qrBgColor: '#f0f0f0',
            qrBorder: '2px dashed #D2691E',
            qrBorderRadius: 30,
            qrShadow: '4px 4px 15px rgba(210, 105, 30, 0.3)',
        },
        'Energy': {
            qrColor: '#FF4500',
            qrBgColor: '#ffffff',
            qrBorder: '3px solid #FF4500',
            qrBorderRadius: 15,
            qrShadow: '5px 5px 20px rgba(255, 69, 0, 0.5)',
        },
        'Monochrome': {
            qrColor: '#333333',
            qrBgColor: '#ffffff',
            qrBorder: '2px solid #333333',
            qrBorderRadius: 10,
            qrShadow: 'none',
        },
        'Graffiti': {
            qrColor: '#FF00FF',
            qrBgColor: '#000000',
            qrBorder: '3px dashed #FF00FF',
            qrBorderRadius: 0,
            qrShadow: '5px 5px 15px rgba(255, 0, 255, 0.5)',
        },
        'Cyberpunk': {
            qrColor: '#00FFFF',
            qrBgColor: '#111111',
            qrBorder: '2px solid #FF00FF',
            qrBorderRadius: 15,
            qrShadow: '4px 4px 20px rgba(0, 255, 255, 0.5)',
        },
        'Rustic': {
            qrColor: '#8B4513',
            qrBgColor: '#F5F5DC',
            qrBorder: '2px solid #8B4513',
            qrBorderRadius: 5,
            qrShadow: '2px 2px 10px rgba(139, 69, 19, 0.5)',
        },
        'Futuristic': {
            qrColor: '#00FF00',
            qrBgColor: '#000000',
            qrBorder: '3px solid #00FF00',
            qrBorderRadius: 25,
            qrShadow: '5px 5px 25px rgba(0, 255, 0, 0.7)',
        },
        'Nature': {
            qrColor: '#2E8B57',
            qrBgColor: '#FAFAD2',
            qrBorder: '2px solid #2E8B57',
            qrBorderRadius: 15,
            qrShadow: '3px 3px 10px rgba(46, 139, 87, 0.5)',
        },
        'Royal': {
            qrColor: '#4B0082',
            qrBgColor: '#FFF8DC',
            qrBorder: '3px solid #4B0082',
            qrBorderRadius: 30,
            qrShadow: '5px 5px 20px rgba(75, 0, 130, 0.5)',
        },
        'Pop Art': {
            qrColor: '#FF1493',
            qrBgColor: '#FFFF00',
            qrBorder: '2px solid #FF1493',
            qrBorderRadius: 10,
            qrShadow: '3px 3px 15px rgba(255, 20, 147, 0.5)',
        },
        'Camo': {
            qrColor: '#556B2F',
            qrBgColor: '#808000',
            qrBorder: '2px solid #556B2F',
            qrBorderRadius: 5,
            qrShadow: '2px 2px 10px rgba(85, 107, 47, 0.5)',
        },
        'Elegant Black & White': {
            qrColor: '#000000',
            qrBgColor: '#ffffff',
            qrBorder: '1px solid #000000',
            qrBorderRadius: 10,
            qrShadow: 'none',
        },
        'Tropical': {
            qrColor: '#32CD32',
            qrBgColor: '#FFD700',
            qrBorder: '2px solid #32CD32',
            qrBorderRadius: 20,
            qrShadow: '3px 3px 15px rgba(50, 205, 50, 0.5)',
        },
        'Metallic': {
            qrColor: '#C0C0C0',
            qrBgColor: '#2F4F4F',
            qrBorder: '3px solid #C0C0C0',
            qrBorderRadius: 20,
            qrShadow: '4px 4px 20px rgba(192, 192, 192, 0.5)',
        },
        'Abstract': {
            qrColor: '#FF6347',
            qrBgColor: '#4682B4',
            qrBorder: '2px solid #FF6347',
            qrBorderRadius: 15,
            qrShadow: '5px 5px 20px rgba(255, 99, 71, 0.5)',
        },
        'Vintage': {
            qrColor: '#B8860B',
            qrBgColor: '#FFFACD',
            qrBorder: '2px solid #B8860B',
            qrBorderRadius: 10,
            qrShadow: '3px 3px 10px rgba(184, 134, 11, 0.5)',
        },

    };
    
    const handlePresetChange = (event) => {
        const selectedPreset = event.target.value;
        setPreset(selectedPreset);
        if (presets[selectedPreset]) {
            const { qrColor, qrBgColor, qrBorder, qrBorderRadius, qrShadow } = presets[selectedPreset];
            setQrColor(qrColor);
            setQrBgColor(qrBgColor);
            setQrBorder(qrBorder);
            setQrBorderRadius(qrBorderRadius);
            setQrShadow(qrShadow);
        }
    };

    const handleLogoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            const fileSize = file.size;
            if (fileSize > 20 * 1024 * 1024) { // 20MB
                setError('File size exceeds 20MB.');
                return;
            }
            if (!['image/jpeg', 'image/png'].includes(fileType)) {
                setError('Only JPG and PNG formats are allowed.');
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                setCustomLogo(reader.result);
                setError('');
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDeleteLogo = () => {
        setCustomLogo(null);
    };

    const handleDownload = () => {
        if (inputValue.trim() === '') return; // Check if input is empty
        setLoading(true);
        const qrCodeElement = document.querySelector('.qr-code-container canvas');

        html2canvas(qrCodeElement).then((canvas) => {
            canvas.toBlob((blob) => {
                if (blob) {
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = `qrcode.${imageFormat}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                }
                setLoading(false);
            }, `image/${imageFormat}`);
        }).catch((err) => {
            setLoading(false);
            setError('Failed to generate QR Code.');
        });
    };
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleCloseError = () => {
        setError('');
    };

    return (
        <Container maxWidth="md" className="qr-generator-container">
                <h1 className='qr-title'>QR Code Generator</h1>
            <Typography variant="h3" gutterBottom>
            </Typography>
            <Box className="qr-content" p={2} display="grid" gap={3}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Data Type</InputLabel>
                    <Select
                        value={dataType}
                        onChange={(e) => setDataType(e.target.value)}
                        label="Data Type"
                    >
                        <MenuItem value="url">URL</MenuItem>
                        <MenuItem value="pdf">PDF</MenuItem>
                        <MenuItem value="text">Plain Text</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="phone">Phone</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    label={`Enter ${dataType === 'url' ? 'URL' : dataType === 'pdf' ? 'PDF Link' : dataType === 'text' ? 'Text' : dataType === 'email' ? 'Email' : 'Phone'}`}
                    variant="outlined"
                    fullWidth
                    value={inputValue}
                    onChange={handleInputChange}
                    style={{ marginBottom: 20 }}
                    required
                />

                <FormControl fullWidth variant="outlined">
                    <InputLabel>Preset Styles</InputLabel>
                    <Select
                        value={preset}
                        onChange={handlePresetChange}
                        label="Preset Styles"
                    >
                        <MenuItem value="" disabled>Select a Preset</MenuItem>
                        {Object.keys(presets).map((preset) => (
                            <MenuItem key={preset} value={preset}>{preset}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Box className="qr-customization">
                    <FormControl fullWidth variant="outlined" style={{ marginBottom: 20 }}>
                        <InputLabel>QR Color</InputLabel>
                        <Select
                            value={qrColor}
                            onChange={(e) => setQrColor(e.target.value)}
                            label="QR Color"
                        >
                            {colorOptions.map((color) => (
                                <MenuItem key={color} value={color}>
                                    <div style={{ backgroundColor: color, width: '100%', height: '20px' }}></div>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Background Color</InputLabel>
                        <Select
                            value={qrBgColor}
                            onChange={(e) => setQrBgColor(e.target.value)}
                            label="Background Color"
                        >
                            {bgColorOptions.map((color) => (
                                <MenuItem key={color} value={color}>
                                    <div style={{ backgroundColor: color, width: '100%', height: '20px' }}></div>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box className="qr-code-container" display="flex" flexDirection="column" alignItems="center">
                    <label htmlFor="upload-logo" className="qr-upload-label">
                        <Input id="upload-logo" type="file" onChange={handleLogoUpload} />
                        <Button
                            variant="contained"
                            color="secondary"
                            component="span"
                            startIcon={<CloudUpload />}
                            style={{ marginBottom: 20 }}
                        >
                            {customLogo ? 'Change Custom Logo' : 'Upload Custom Logo'}
                        </Button>
                    </label>

                    <Button
                        className="deleteButton"
                        onClick={handleDeleteLogo}
                        variant="contained"
                        color="secondary"
                        style={{ marginBottom: 20, pointerEvents: customLogo ? 'auto' : 'none', opacity: customLogo ? 1 : 0.5 }}
                        disabled={!customLogo}
                    >
                        Delete Logo
                    </Button>

                    <QRCode
                        value={inputValue}
                        size={256}
                        fgColor={qrColor}
                        bgColor={qrBgColor}
                        imageSettings={{
                            src: customLogo || '',
                            x: null,
                            y: null,
                            height: 50,
                            width: 50,
                            excavate: true,
                        }}
                        style={{
                            border: qrBorder,
                            borderRadius: `${qrBorderRadius}px`,
                            boxShadow: qrShadow,
                        }}
                    />

                    {loading && <CircularProgress style={{ marginTop: 20 }} />}
                </Box>

                <Box display="flex" justifyContent="space-between" gap={2}>
                    <FormControl variant="outlined" style={{ flex: 1 }}>
                        <InputLabel>Image Format</InputLabel>
                        <Select
                            value={imageFormat}
                            onChange={(e) => setImageFormat(e.target.value)}
                            label="Image Format"
                        >
                            <MenuItem value="png">PNG</MenuItem>
                            <MenuItem value="jpeg">JPEG</MenuItem>
                            <MenuItem value="webp">WEBP</MenuItem>
                        </Select>
                    </FormControl>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownload}
                        style={{
                            flex: 1,
                            cursor: inputValue.trim() === '' || loading ? 'not-allowed' : 'pointer',
                        }}
                        disabled={inputValue.trim() === '' || loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Download'}
                    </Button>
                </Box>
            </Box>

            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default QRCodeGenerator;
