import React, { useState } from 'react';
import './ForgotPassword.css';
import usePageTitle from '../../../components/usePageTitle';

const ForgotPassword = () => {
  usePageTitle("Forgot Password - RixStonGamer");

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    // Simulate sending a reset link
    if (email.trim() === '') {
      setError('Please enter a valid email address.');
    } else {
      setMessage('A reset link has been sent to your email address.');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-card">
        <h2>Reset Password</h2>
        <p>Please enter your registered email id to reset your password.</p>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="btn">Send Reset Link</button>
        </form>
        <div className="or-container">
          <hr className="line" />
          <p className="or-text">Or</p>
          <hr className="line" />
        </div>
        <div className="btn-group">
          <a className='btn-login' href="/login">LOGIN</a>
          <a className='btn-signup' href="/signup">REGISTER</a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
