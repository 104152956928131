import React, { useState, useEffect, useRef } from 'react';
import './Gallery.css';
import usePageTitle from '../../components/usePageTitle';

// Gallery Images
import image1 from '../../assets/image/image1.jpg';
import image2 from '../../assets/image/image2.jpg';
import image3 from '../../assets/image/image3.jpg';
import image4 from '../../assets/image/image4.jpg';
import image5 from '../../assets/image/image5.jpg';
import image6 from '../../assets/image/image6.jpg';
import image7 from '../../assets/image/image7.jpg';
import image8 from '../../assets/image/image8.jpg';
import image9 from '../../assets/image/image9.jpg';
import image10 from '../../assets/image/image10.jpg';

const images = [
  image1, image2, image3, image4, image5,
  image6, image7, image8, image9, image10
];

function Gallery() {
  usePageTitle("Gallery - RixStonGamer");
  const [fullScreen, setFullScreen] = useState(null);
  const startX = useRef(0);
  const endX = useRef(0);

  const openFullScreen = (index) => {
    setFullScreen(index);
  };

  const closeFullScreen = () => {
    setFullScreen(null);
  };

  const nextImage = () => {
    setFullScreen((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setFullScreen((prev) => (prev - 1 + images.length) % images.length);
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = images[fullScreen];
    link.download = `image-${fullScreen + 1}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const shareImage = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Check out this image!',
        url: images[fullScreen],
      }).catch(console.error);
    } else {
      alert('Share feature not supported on this browser.');
    }
  };

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    endX.current = e.changedTouches[0].clientX;
    if (startX.current - endX.current > 50) {
      nextImage();
    } else if (endX.current - startX.current > 50) {
      prevImage();
    }
  };

  useEffect(() => {
    if (fullScreen !== null) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [fullScreen]);

  return (
    <div className="gallery">
      <h1>Gallery</h1>
      <div className={`gallery-container ${fullScreen !== null ? 'blurred' : ''}`}>
        {images.map((src, index) => (
          <div 
            className="gallery-item" 
            key={index} 
            onClick={() => openFullScreen(index)}
          >
            <img src={src} alt={`Gallery item ${index + 1}`} />
          </div>
        ))}
      </div>

      {fullScreen !== null && (
        <div className="full-screen" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
          <button className="close-btn" onClick={closeFullScreen}><i className="fas fa-times"></i></button>
          <button className="prev-btn" onClick={prevImage}><i className="fas fa-chevron-left"></i></button>
          <button className="next-btn" onClick={nextImage}><i className="fas fa-chevron-right"></i></button>
          <img src={images[fullScreen]} alt={`Full screen ${fullScreen + 1}`} />
          <div className="full-screen-actions">
            <button className="download-btn" onClick={downloadImage}>
              <svg
                id="download"
                viewBox="0 0 24 24"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.29,17.29,13,18.59V13a1,1,0,0,0-2,0v5.59l-1.29-1.3a1,1,0,0,0-1.42,1.42l3,3a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l3-3a1,1,0,0,0-1.42-1.42ZM18.42,6.22A7,7,0,0,0,5.06,8.11,4,4,0,0,0,6,16a1,1,0,0,0,0-2,2,2,0,0,1,0-4A1,1,0,0,0,7,9a5,5,0,0,1,9.73-1.61,1,1,0,0,0,.78.67,3,3,0,0,1,.24,5.84,1,1,0,1,0,.5,1.94,5,5,0,0,0,.17-9.62Z"
                ></path>
              </svg>
            </button>
            <button className="share-btn" onClick={shareImage}><i className="fas fa-share-alt"></i></button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Gallery;
