import React, { useState } from 'react';
import usePageTitle from '../../components/usePageTitle';
import './TicTacToe.css';

const TicTacToe = () => {
  usePageTitle("TicTacToe - RixStonGamer");
  const [squares, setSquares] = useState(Array(9).fill(''));
  const [currentPlayer, setCurrentPlayer] = useState('X');
  const [gameStarted, setGameStarted] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [showRestart, setShowRestart] = useState(false);
  const [winner, setWinner] = useState(null);
  const [showStartPopup, setShowStartPopup] = useState(false);

  const handleSquareClick = (index) => {
    if (!gameStarted) {
      setShowStartPopup(true);
      return;
    }
    if (gameEnded || squares[index] !== '') return;

    if (!showRestart) {
      setShowRestart(true);
    }

    const newSquares = squares.slice();
    newSquares[index] = currentPlayer;
    setSquares(newSquares);

    if (checkWinner(newSquares)) {
      setWinner(currentPlayer);
      setGameEnded(true);
    } else if (checkDraw(newSquares)) {
      setWinner('Draw');
      setGameEnded(true);
    } else {
      setCurrentPlayer(currentPlayer === 'X' ? 'O' : 'X');
    }
  };

  const checkWinner = (squares) => {
    const winningCombos = [
      [0, 1, 2], [3, 4, 5], [6, 7, 8],
      [0, 3, 6], [1, 4, 7], [2, 5, 8],
      [0, 4, 8], [2, 4, 6]
    ];

    return winningCombos.some(combo => {
      const [a, b, c] = combo;
      return squares[a] && squares[a] === squares[b] && squares[a] === squares[c];
    });
  };

  const checkDraw = (squares) => {
    return squares.every(square => square);
  };

  const handleStart = () => {
    setGameStarted(true);
    setShowRestart(false);
  };

  const handleRestart = () => {
    setSquares(Array(9).fill(''));
    setCurrentPlayer('X');
    setGameStarted(true);
    setGameEnded(false);
    setWinner(null);
    setShowRestart(false);
  };

  const handleNewGame = () => {
    setSquares(Array(9).fill(''));
    setCurrentPlayer('X');
    setGameStarted(false);
    setGameEnded(false);
    setWinner(null);
    setShowRestart(false);
  };

  const handleExit = () => {
    window.location.href = '/projects';
  };

  return (
    <div className="container">
      <h1 id="winnerName" className="winner-name">Tic-Tac-Toe</h1>
      <div className="turn-indicator">
        {gameStarted && !gameEnded && (
          <div className={`turn ${currentPlayer === 'X' ? 'active' : ''}`}>X's Turn</div>
        )}
        {gameStarted && !gameEnded && (
          <div className={`turn ${currentPlayer === 'O' ? 'active' : ''}`}>O's Turn</div>
        )}
      </div>
      <div id="gameBoard" className="game-board">
        {squares.map((square, index) => (
          <div
            key={index}
            className={`square ${square !== '' ? 'occupied' : ''}`}
            onClick={() => handleSquareClick(index)}
          >
            {square}
          </div>
        ))}
      </div>
      {winner && (
        <div id="winnerPopup" className="popup">
          {winner === 'Draw' ? 'Tie' : `${winner} Wins!`}
        </div>
      )}
      {showStartPopup && (
        <div id="startPopup" className="popup" onClick={() => setShowStartPopup(false)}>
          Start Game to Play
        </div>
      )}
      {!gameStarted && (
        <button className="btn" onClick={handleStart}>
          Start Game
        </button>
      )}
      {gameStarted && !gameEnded && !showRestart && (
        <button className="btn exit-btn" onClick={handleExit}>
          Exit Game
        </button>
      )}
      {gameStarted && showRestart && !gameEnded && (
        <button className="btn" onClick={handleRestart}>
          Restart Game
        </button>
      )}
      {gameEnded && (
        <>
          <button className="btn" onClick={handleNewGame}>
            New Game
          </button>
          <button className="btn exit-btn" onClick={handleExit}>
            Exit Game
          </button>
        </>
      )}
    </div>
  );
};

export default TicTacToe;
