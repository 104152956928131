import React from 'react';
import './Services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faCode, faImage, faPaintBrush } from '@fortawesome/free-solid-svg-icons';
import usePageTitle from '../../components/usePageTitle';

function Services() {
  usePageTitle("Services - RixStonGamer");
  return (
    <div className="services">
      <h1>Our Services</h1>
      <div className="experience-items">
            <div className="experience-item">
              <FontAwesomeIcon icon={faVideo} className="experience-icon" />
              <h3>Video Editor</h3>
              <p>Expert in video editing, color correction, and motion graphics for engaging content.</p>
            </div>
            <div className="experience-item">
              <FontAwesomeIcon icon={faPaintBrush} className="experience-icon" />
              <h3>Graphic Designer</h3>
              <p>Designing creative and effective graphics and visuals for various applications.</p>
            </div>
            <div className="experience-item">
              <FontAwesomeIcon icon={faImage} className="experience-icon" />
              <h3>Logo Designer</h3>
              <p>Logos that reflect brand identity.</p>
            </div>
            <div className="experience-item">
              <FontAwesomeIcon icon={faImage} className="experience-icon" />
              <h3>Thumbnail Designer</h3>
              <p>Creating attention-grabbing thumbnails.</p>
            </div>
            <div className="experience-item">
              <FontAwesomeIcon icon={faCode} className="experience-icon" />
              <h3>Discord Server Developer</h3>
              <p>Specializing in designing interactive Discord servers with advanced customizations.</p>
            </div>
          </div>
    </div>
  );
}

export default Services;
